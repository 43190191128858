﻿import { global } from "../Common/saop-common";
import { SaopServicesCore } from "../Core/saop-services-core";

export enum SaopRightSidebarView {
    Dashboard = 0
    ,Absence = 1
    ,AttendanceDay = 2
    ,AttendanceMonth = 3    
    ,TravelOrders = 4
    ,InternalOrders = 5
}

export class SaopRightSidebar {
    private _saopServicesCore :SaopServicesCore;

    constructor() {
        this._saopServicesCore = new SaopServicesCore();
    }


    bindCarouselDiv(contentId:string,view:SaopRightSidebarView){
        global.ConsoleLogDegug("SaopRightSidebar.bindDiv"); 
        this._saopServicesCore.afterDone = function(response){
            $(contentId).html(response);
        };
        this._saopServicesCore.ExecuteAction({
            action:"RightSideBar/GetCarouselView?view="+view.toString()
            ,data:null
            ,type:"GET"
            ,spinnerDivId:"#saopLoadingSidebarr"
            ,async:false
        });           
    }

    refreshDopustZaKoriscenjeSummary(contentId: string, spinnerDivId: string = "#saopLoadingSidebarr"): void {
        global.ConsoleLogDegug("SaopRightSidebar.refreshDopustZaKoriscenjeSummary");

        this._saopServicesCore.afterDone = function(response){
            $(contentId).html(response);
        };
        this._saopServicesCore.ExecuteAction({
            action:"RightSideBar/DopustZaKoriscenjeSummaryPartial"
            ,data:null
            ,type:"GET"
            ,spinnerDivId: spinnerDivId
        });        
    }

    refreshUreZaKoriscenjeSummary(contentId: string, spinnerDivId: string = "#saopLoadingSidebarr"): void {
        global.ConsoleLogDegug("SaopRightSidebar.refreshUreZaKoriscenjeSummary");

        this._saopServicesCore.afterDone = function(response){
            $(contentId).html(response);
        };
        this._saopServicesCore.ExecuteAction({
            action:"RightSideBar/UreZaKoriscenjeSummaryPartial"
            ,data:null
            ,type:"GET"
            ,spinnerDivId: spinnerDivId
        });
    }

    refreshRegUraDogodkiSummary(contentId: string, selectedPeriod:string, spinnerDivId:string): void {
        global.ConsoleLogDegug("SaopRightSidebar.refreshRegUraDogodkiSummary");

        let _this = this;
        this._saopServicesCore.afterDone = function(response){
            $(contentId).html(response);
            _this.setHeight();
            window.onresize = function () {
                _this.setHeight();
            };
        };
        this._saopServicesCore.ExecuteAction({
            action:"RightSideBar/RegUraDogodkiSummaryPartial?selectedPeriod="+selectedPeriod
            ,data:null
            ,type:"GET"
            ,spinnerDivId:spinnerDivId
            //,spinnerDivId:"#saopLoadingSidebarr"
        });
    }

    refreshRegOPNPotniNalogiSummary(contentId: string, spinnerDivId:string): void {
        global.ConsoleLogDegug("SaopRightSidebar.refreshRegOPNPotniNalogiSummary");

        this._saopServicesCore.afterDone = function(response){
            $(contentId).html(response);
        };
        this._saopServicesCore.ExecuteAction({
            action:"TravelOrder/TravelOrdersSummary"
            ,data:null
            ,type:"GET"
            ,spinnerDivId:spinnerDivId
            //,spinnerDivId:"#saopLoadingSidebarr"
        });
    }

    refreshNdkInternalOrdersSummary(contentId: string, spinnerDivId: string): void {
        global.ConsoleLogDegug("SaopRightSidebar.refreshNdkInternalOrdersSummary");

        this._saopServicesCore.afterDone = function (response) {
            $(contentId).html(response);
        };
        this._saopServicesCore.ExecuteAction({
            action: "RightSideBar/InternalOrdersSummary"
            , data: null
            , type: "GET"
            , spinnerDivId: spinnerDivId
            //,spinnerDivId:"#saopLoadingSidebarr"
        });
    }

    setHeight() {
        var header = $("#site-header").height();
        var footer = $("#site-footer").height();

        var visina_tab_lista = 0;
        if (window.location.pathname == '/REGAttendance')
            visina_tab_lista = 37;

        $("#reg-summary-container").height(window.innerHeight - header - footer - 106 - visina_tab_lista);
    }

    //
}




